import React from "react";
import { Helmet } from "react-helmet";
import { useGetTermsConditionQuery } from "../products/productSlice";

function TermsOfUse() {
  const { data, isLoading } = useGetTermsConditionQuery();
  console.log(data);
  return (
    <>
      <Helmet>
        <title>Terms & Condition | Fertilizer Multi Vendor</title>
        <meta name="keyword" content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition" />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <section className="termsSec">
        <div className="container">
          <div className="termsInfo">
            {isLoading && (
              <div className="preloaderCount">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <h4>Terms And conditions :</h4>
            <p>You should carefully read the following Terms and Conditions. Your purchase that you have read and accepted these Terms and Conditions.</p>
            <div class="panel-body">
              <p>
                1. <strong>Site Content</strong>
              </p>
              <p>
                The information contained on the site www.mamastycoon.com are updated periodically. Photographs and colors illustrating the products are non contractual. If errors
                occur, shall in no case the responsibility mamastycoon not be engaged.
              </p>
              <p>
                In an attempt to provide you with increased value, we may include third party links on our site. These linked sites have separate and independent privacy policies.
                We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and
                welcome any feedback about these linked sites (including if a specific link does not work).
              </p>

              <p>
                2. <strong> Your consent </strong>
              </p>
              <p>By using our site, you consent to our Privacy Policy</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermsOfUse;
