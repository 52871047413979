import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";
import { AiFillCar } from "react-icons/ai";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { FaMoneyBill } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";

const imgs = [etg, kynoch, falcon, oemff];

function Brands() {
  // const { data, isLoading, error } = useListAllBrandQuery()

  return (
    <>
      <div className="brandSec">
        <div className="container-fluid">
          <div className="brandsSec">
            <div className="mainRap">
              <div className="icon">
                <AiFillCar />
              </div>
              <div className="text">
                <h6>Free Shipping</h6>
                <p>
                  Free same day delivery within Doha on orders above 200 QAR.
                </p>
              </div>
            </div>
            <div className="mainRap">
              <div className="icon">
                <RiArrowGoForwardLine />
              </div>
              <div className="text">
                <h6>Full Refund</h6>
                <p>
                  Didn't like it?. No problem! simply return it within 7 days
                  and get a full refund.
                </p>
              </div>
            </div>
            <div className="mainRap">
              <div className="icon">
                <FaMoneyBill />
              </div>
              <div className="text">
                <h6>Debit & Credit Card</h6>
                <p>
                  Don't have a Credit Card? We accept any debit card issued in
                  Qatar.
                </p>
              </div>
            </div>
            <div className="mainRap">
              <div className="icon">
                <BsShieldLockFill />
              </div>
              <div className="text">
                <h6>100% Secure Payment</h6>
                <p>
                  We ensure 100% secure payment through accredited 3rd party.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brands;
