import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
import suitBanner1 from "../../../assets/img/suitBanner1.jpg";
import suitBanner2 from "../../../assets/img/suitBanner2.jpg";
import suitBanner3 from "../../../assets/img/suitBanner3.jpg";
const bannerData = bannerDB;
function Banner() {
  // Custom Data
  const custom = [
    { id: 1, url: suitBanner1 },
    { id: 2, url: suitBanner2 },
    { id: 3, url: suitBanner3 },
  ];

  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        <div className="">
          <Slider {...settings}>
            {data?.length > 0
              ? data.map((item) => {
                  return (
                    item?.image?.url && (
                      <Link to={item?.url || ""} key={item._id}>
                        <div className="bannerItem">
                          <img src={item?.image.url} />
                        </div>
                      </Link>
                    )
                  );
                })
              : custom.map((item) => {
                  return (
                    item?.url && (
                      <Link to={item?.url || ""} key={item.id}>
                        <div className="bannerItem">
                          <img src={item?.url} />
                        </div>
                      </Link>
                    )
                  );
                })}
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Banner;
